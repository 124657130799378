import classNames from 'classnames'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import BSCard from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import ArticlePreview from '@/components/ArticlePreview/ArticlePreview'
import Card from '@/components/Card/Card'
import CardHover from '@/components/CardHover/CardHover'
import ContentPreview from '@/components/ContentPreview/ContentPreview'
import { ExplicitHead } from '@/components/DefaultHead/DefaultHead'
import Main from '@/components/Main/Main'
import OutboundLink from '@/components/OutboundLink/OutboundLink'
import requestService from '@/services/request.service'
import { ArticleType, DailyResponseType, WordpressResponseType } from '@/types/home'

import styles from './index.module.scss'

type Props = {
  enableAdhesion: boolean
  initialDaily: DailyResponseType
  initialWordpress: WordpressResponseType
}

const DISPLAY_COMMUNITY_CONTENT = false

const HomePage = ({ enableAdhesion, initialWordpress, initialDaily }: Props) => {
  const [dailyCommander] = useState(initialDaily)
  const [articles, setArticles] = useState(initialWordpress.articles)
  const [content, setContent] = useState(initialWordpress.content)

  const [articlesPage, setArticlesPage] = useState(0)
  const [contentPage, setContentPage] = useState(0)
  const [isLoadingMoreArticles, setIsLoadingMoreArticles] = useState(false)
  const [isLoadingMoreContent, setIsLoadingMoreContent] = useState(false)

  const fetchMoreArticles = async () => {
    setIsLoadingMoreArticles(true)
    const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/wordpress/articles/${articlesPage + 1}`)
    const json = await res.json()
    setArticlesPage(articlesPage + 1)
    setArticles([...articles, ...json])
    setIsLoadingMoreArticles(false)
  }

  const fetchMoreContent = async () => {
    setIsLoadingMoreContent(true)
    const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/wordpress/content/${contentPage + 1}`)
    const json = await res.json()
    setContentPage(contentPage + 1)
    setContent([...content, ...json])
    setIsLoadingMoreContent(false)
  }

  return (
    <Main>
      <div className={styles.container}>
        <ExplicitHead
          title='EDHREC'
          description='EDH Recommendations and strategy content for Magic: the Gathering Commander'
          keywords='EDHREC,EDH,recommendations,Commander,MTG,Magic,staples,strategy,content'
        />
        <div className={styles.containerLeft}>
          {articles
            .slice(0, -1)
            .reduce<ArticleType[][]>((res, _, i, arr) => {
              if (i % 2 === 0) res.push(arr.slice(i, i + 2))
              return res
            }, [])
            .map((articleRow) => (
              <div
                key={articleRow.map((article) => article.link).join()}
                className={classNames(styles.grid, styles.row)}
              >
                {articleRow.map((article) => (
                  <div key={article.link} className={styles.rowItem}>
                    {article.youtube ? <ContentPreview content={article} /> : <ArticlePreview article={article} />}
                  </div>
                ))}
              </div>
            ))}
          <div className={styles.row}>
            <span className={styles.loadMore}>
              <Button onClick={fetchMoreArticles} variant='link'>
                {isLoadingMoreArticles ? <Spinner animation='border' /> : 'Load More Articles'}
              </Button>
            </span>
          </div>
          {DISPLAY_COMMUNITY_CONTENT && (
            <>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <h2>
                    <OutboundLink
                      title='Community Content'
                      url='https://articles.edhrec.com/author/contentadmin/'
                      utm_medium='homepage'
                    >
                      Community Content
                    </OutboundLink>
                  </h2>
                </div>
              </div>
              {content
                .reduce<Array<Array<ArticleType>>>((res, _, i, arr) => {
                  if (i % 2 === 0) res.push(arr.slice(i, i + 2))
                  return res
                }, [])
                .map((contentRow) => (
                  <div
                    key={contentRow.map((content) => content.link).join()}
                    className={classNames(styles.grid, styles.row)}
                  >
                    {contentRow.map((content) => (
                      <div key={content.link} className={styles.rowItem}>
                        <ContentPreview content={content} />
                      </div>
                    ))}
                  </div>
                ))}
              <div className={styles.row}>
                <span className={styles.loadMore}>
                  <Button onClick={fetchMoreContent} variant='link'>
                    {isLoadingMoreContent ? <Spinner animation='border' /> : 'Load More Community Content'}
                  </Button>
                </span>
              </div>
            </>
          )}
        </div>
        <div className={styles.containerRight}>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.rowContent}>
                <h5 className='title pb-2'>Commander of the Day</h5>
                <div className={styles.dailyCommander}>
                  <Card card={dailyCommander.daily} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <h5 className='title'>Top Commanders Weekly</h5>
              <BSCard className={classNames(styles.rowContent, 'shadow-sm')}>
                <BSCard.Body>
                  <ul>
                    {dailyCommander.weekly.map((item, i) => (
                      <li key={i}>
                        {i + 1}. <CardHover card={item} left />
                      </li>
                    ))}
                  </ul>
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
          {dailyCommander?.trending && (
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <h5 className='title'>Trending Cards</h5>
                <BSCard className={classNames(styles.rowContent, 'shadow-sm')}>
                  <BSCard.Body>
                    <ul>
                      {dailyCommander.trending.map((item, i) => (
                        <li key={i}>
                          {i + 1}. <CardHover card={item} left />
                        </li>
                      ))}
                    </ul>
                  </BSCard.Body>
                </BSCard>
              </div>
            </div>
          )}
        </div>
      </div>
      {enableAdhesion && <div className='mvHomeAdhesion' />}
    </Main>
  )
}

export const getServerSideProps = async () => {
  try {
    const daily = await (await requestService.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/daily`)).json()
    const wordpress = await (
      await requestService.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/wordpress`)
    ).json()
    return {
      props: {
        enableAdhesion: process.env.BUILD_TYPE === 'beta',
        initialDaily: daily,
        initialWordpress: wordpress,
      },
    }
  } catch (error) {
    throw new Error('Failed to fetch data')
  }
}

export default HomePage
