import classNames from 'classnames'
import { Fragment } from 'react'
import Card from 'react-bootstrap/Card'

import OutboundLink from '@/components/OutboundLink/OutboundLink'
import { ArticleType } from '@/types/home'
import { ArticleListEntryType } from '@/types/page'

import styles from './ArticlePreview.module.scss'

type Props = {
  article: ArticleType | ArticleListEntryType
  small?: boolean
  square?: boolean
}

const ArticlePreview = ({ article, small, square }: Props) => {
  const { author, date, excerpt, link, media, site, tags, title, href, value } = article

  const articleLink = link || href
  if (!articleLink) return null

  let authorName = author?.name
  if (site && site.id !== 'edhrec') {
    authorName += ` — ${site.name}`
  }

  return (
    <div className={styles.container}>
      <Card className={classNames('h-100 m-0 shadow-sm', { [styles.small]: small, 'rounded-0': square })}>
        <OutboundLink url={articleLink} utm_medium='article_preview'>
          <Card.Img className={classNames(styles.media, { 'rounded-0': square })} variant='top' src={media} />
        </OutboundLink>
        <Card.Body className='d-flex flex-column'>
          {author ? (
            <OutboundLink url={author.link} utm_medium='article_preview'>
              <div className={styles.avatar}>
                <img alt={author.name} src={author.avatar} />
              </div>
            </OutboundLink>
          ) : null}
          <div className='d-flex flex-column justify-content-between'>
            <div className={styles.body}>
              <div className='align-items-center d-flex justify-content-between'>
                <div className={styles.date}>{date}</div>
                <div className={styles.author}>
                  {author ? (
                    <OutboundLink
                      dangerouslySetInnerHTML={{ __html: authorName }}
                      url={author.link}
                      utm_medium='article_preview'
                    />
                  ) : null}
                </div>
              </div>
              <h5 className={styles.title}>
                <OutboundLink url={articleLink} utm_medium='article_preview'>
                  {title || value}
                </OutboundLink>
              </h5>
              <div className={styles.excerpt}>{excerpt}</div>
            </div>
            {tags && tags.length > 0 && (
              <div className='d-none d-md-block'>
                <hr />
                <div className={styles.tags}>
                  {tags.map(({ link, name }, i) => (
                    <Fragment key={link}>
                      <OutboundLink url={articleLink} utm_medium='article_preview'>
                        {`#${name}`}
                      </OutboundLink>
                      {i !== tags.length - 1 && ', '}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ArticlePreview
